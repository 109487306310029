<template>
  <div class="jj-form">
    <div class="tips-text">
      (企业注册时间超过三年必须填写最近三年经济数据，企业注册时间不足三年，请填写注册当年到去年所有数据)
    </div>
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane
        v-for="(item, index) in formList"
        :key="index"
        :label="item.name"
        :name="item.value"
      >
      </el-tab-pane>
      <el-row :gutter="0">
        <el-form
          ref="elForm"
          :model="formData"
          :rules="rules"
          size="small"
          label-width="144px"
        >
          <el-col :span="8">
            <el-form-item label="营业收入(万元)" prop="yysr">
              <el-input
                v-model="formData.yysr"
                placeholder="请输入营业收入(万元)"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
              >
                <span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="主要业务收入(万元)" prop="zyywsr">
              <el-input
                v-model="formData.zyywsr"
                placeholder="请输入主要业务收入(万元)"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="销售收入(万元)" prop="xssr">
              <el-input
                v-model="formData.xssr"
                placeholder="请输入销售收入(万元)"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="净利润" prop="jlr">
              <el-input
                v-model="formData.jlr"
                placeholder="请输入净利润"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="净资产" prop="jzc">
              <el-input
                v-model="formData.jzc"
                placeholder="请输入净资产"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总资产" prop="zzc">
              <el-input
                v-model="formData.zzc"
                placeholder="请输入总资产"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出口总额" prop="ckze">
              <el-input
                v-model="formData.ckze"
                placeholder="请输入出口总额"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="进口总额" prop="jkze">
              <el-input
                v-model="formData.jkze"
                placeholder="请输入进口总额"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="负债总额" prop="fzze">
              <el-input
                v-model="formData.fzze"
                placeholder="请输入负债总额"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="毛利" prop="ml">
              <el-input
                v-model="formData.ml"
                placeholder="请输入毛利"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="研发投入" prop="yftl">
              <el-input
                v-model="formData.yftl"
                placeholder="请输入研发投入"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="重大项目投资" prop="zdxmtz">
              <el-input
                v-model="formData.zdxmtz"
                placeholder="请输入重大项目投资"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="纳税总额" prop="nsze">
              <el-input
                v-model="formData.nsze"
                placeholder="请输入纳税总额"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="区纳税总额" prop="qnsze">
              <el-input
                v-model="formData.qnsze"
                placeholder="请输入区纳税总额"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
              ><span slot="append">万元</span>
</el-input>
            </el-form-item>
          </el-col> -->
          <el-col :span="8">
            <el-form-item label="纳税证明" prop="nszm">
              <el-input
                v-model="formData.nszm"
                placeholder="请输入纳税证明"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业所得税" prop="qysds">
              <el-input
                v-model="formData.qysds"
                placeholder="请输入企业所得税"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="增值税" prop="zzs">
              <el-input
                v-model="formData.zzs"
                placeholder="请输入增值税"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
						<el-form-item label="营业收入" prop="yysr">
							<el-input v-model="formData.yysr" placeholder="请输入营业收入" clearable :style="{width: '100%'}" :disabled="tijBtnShow == false" ><span slot="append">万元</span>
</el-input>
						</el-form-item>
						</el-col> -->
          <el-col :span="8">
            <el-form-item label="个人所得税" prop="zrsds">
              <el-input
                v-model="formData.zrsds"
                placeholder="请输入个人所得税"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其它税" prop="qts">
              <el-input
                v-model="formData.qts"
                placeholder="请输入其它税"
                clearable
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="备注" prop="bz">
              <el-input
                v-model="formData.bz"
                type="textarea"
                placeholder="请输入备注"
                :autosize="{ minRows: 4, maxRows: 4 }"
                :style="{ width: '100%' }"
                :disabled="tijBtnShow == false"
                ><span slot="append">万元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <!-- <el-form-item label="" v-show="tijBtnShow">
              <span style="color: red">编辑后请点击保存按钮！</span>
            </el-form-item> -->
            <el-form-item size="large">
              <!-- <el-button  v-show="tijBtnShow == true"  type="primary" @click="submitForm">{{  formData.year && formData.year == ( 2020 - parseInt(activeName)) && formData.id ? '修改' : '新建' }}</el-button> -->
              <el-button
                v-show="tijBtnShow == true"
                type="primary"
                @click="submitForm"
                >保存</el-button
              >
              <!-- <el-button @click="tijBtnShow = !tijBtnShow" type="primary">{{
                tijBtnShow ? "取消" : "编辑"
              }}</el-button> -->
              <!-- <el-button @click="resetForm">重置</el-button> -->
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
      <!-- <el-tab-pane label="2019" name="1">配置管理</el-tab-pane>
			<el-tab-pane label="2018" name="2">角色管理</el-tab-pane> -->
    </el-tabs>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data() {
    return {
      initialIndex: 0,
      /* tabs */
      activeName: "0",
      /* 是否编辑 */
      tijBtnShow: true,
      /* 获取的列表信息 */
      formList: [
        {
          value: "0",
          name: "2022",
        },
        {
          value: "1",
          name: "2021",
        },
        {
          value: "2",
          name: "2020",
        },
      ],
      /* 展示参数 */
      formData: {
        yysr: "",
        zyywsr: "",
        xssr: "",
        jlr: "",
        jzc: "",
        zzc: "",
        ckze: "",
        jkze: "",
        fzze: "",
        ml: "",
        yftl: "",
        zdxmtz: "",
        nsze: "",
        qnsze: "",
        nszm: "",
        qysds: "",
        zzs: "",
        yysr: "",
        zrsds: "",
        qts: "",
        bz: "",
        createTime: "",
        updateTime: "",
        // webEid: '',
        year: "", // 年份
      },
      /* 暂存三年的 */
      threeYearData: [],
      newData: {
        yysr: "",
        zyywsr: "",
        xssr: "",
        jlr: "",
        jzc: "",
        zzc: "",
        ckze: "",
        jkze: "",
        fzze: "",
        ml: "",
        yftl: "",
        zdxmtz: "",
        nsze: "",
        qnsze: "",
        nszm: "",
        qysds: "",
        zzs: "",
        yysr: "",
        zrsds: "",
        qts: "",
        bz: "",
        createTime: "",
        updateTime: "",
        // webEid: '',
        year: "", // 年份
      },
      /* 获取的所有列表参数 */
      allFormList: [],
      rules: {
        yysr: [
          {
            required: true,
            message: "请输入营业收入(万元)",
            trigger: "blur",
          },
        ],
        zyywsr: [],
        xssr: [
          {
            required: true,
            message: "请输入销售收入(万元)",
            trigger: "blur",
          },
        ],
        jlr: [
          {
            required: true,
            message: "请输入净利润",
            trigger: "blur",
          },
        ],
        jzc: [
          {
            required: true,
            message: "请输入净资产",
            trigger: "blur",
          },
        ],
        zzc: [
          {
            required: true,
            message: "请输入总资产",
            trigger: "blur",
          },
        ],
        ckze: [],
        jkze: [
          {
            required: true,
            message: "请输入进口总额",
            trigger: "blur",
          },
        ],
        fzze: [
          {
            required: true,
            message: "请输入负债总额",
            trigger: "blur",
          },
        ],
        ml: [
          {
            required: true,
            message: "请输入毛利",
            trigger: "blur",
          },
        ],
        yftl: [
          {
            required: true,
            message: "请输入研发投入",
            trigger: "blur",
          },
        ],
        zdxmtz: [],
        nsze: [
          {
            required: true,
            message: "请输入纳税总额",
            trigger: "blur",
          },
        ],
        // qnsze: [
        //   {
        //     required: true,
        //     message: "请输入区纳税总额",
        //     trigger: "blur",
        //   },
        // ],
        nszm: [],
        qysds: [],
        zzs: [],
        // yysr: [],
        zrsds: [],
        qts: [],
        bz: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      accountId: "getAccountId",
    }),
  },
  watch: {},
  created() {
    // this.formData.webEid = this.newData.webEid = this.accountId
    let activeName = sessionStorage.getItem("activeName");
    if (activeName) {
      this.activeName = activeName;
      this.initialIndex = Number(activeName);
    }
    this.getJJZBThree(); // get经济指标近三年填报
  },
  mounted() {},
  methods: {
    /**
     * get经济指标近三年填报
     */
    getJJZBThree() {
      let url = "/dev-api/enterprise/jjzb/list";
      let params = {
        pageNum: 0,
        pageSize: 100,
        webEid: this.userInfo.tyshxydm,
        // webEid: this.accountId
      };
      console.log("提交参数>>>", params);
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("get经济指标近三年填报>>>", res);
          if (res.code === 200) {
            let resData = JSON.parse(JSON.stringify(res.rows));
            console.log("get经济指标近三年填报res.rows>>>", resData);
            const resLen = resData.length;
            if (resLen === 0) {
              // 没有数据的时候
              this.formData = this.newData;
            } else {
              const newData = this.newData;
              let target = [newData, newData, newData];
              for (const item of resData) {
                switch (item.year) {
                  case "2022":
                    target[0] = item;
                    break;
                  case "2021":
                    target[1] = item;
                    break;
                  case "2020":
                    target[2] = item;
                    break;
                }
              }
              console.log("处理后的暂存数组，三年>>>", target);
              this.threeYearData = target;
              const isShow = this.threeYearData[this.initialIndex]; // 初始
              if (isShow.year) {
                this.formData = isShow;
              }
            }
          }
        })
        .catch((err) => {});
    },
    /**
     * 新建经济指标
     */
    addJJZB() {
      let url = "/dev-api/enterprise/jjzb/install";
      this.formData.year = 2022 - parseInt(this.activeName) + "";
      let params = this.formData;
      params.webEid = this.userInfo.tyshxydm;
      // params.id = this.userInfo.tyshxydm
      this.formData;
      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("新建经济指标>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
            sessionStorage.setItem("indexActiveName", "jjzb");
            sessionStorage.setItem("activeName", this.activeName);
            this.$router.go(0);
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * 修改
     */
    upJJZB() {
      let url = "/dev-api/enterprise/jjzb/update";
      let params = this.formData;
      for (let k in params) {
        if (params[k] == "") {
          params[k] = 0;
        }
      }
      console.log("xiu提交参数>>>", params);
      delete params.createTime;
      delete params.updateTime;
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("修改经济指标>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.$router.go(0);
            // this.tijBtnShow = false;
          } else if (res.code === 500) {
            this.$message({
              // message: "参数错误！",
              message: res.msg,
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * tabs 切换
     */
    handleClick(tab, event) {
      console.log("切换>>>", tab.label, event);
      const e = parseInt(tab.index);
      this.formData = this.threeYearData[e]; // 初始
      // const isData = this.formData
      // if ( !(isData.jlr) ) {
      // 	console.log("不存在")
      // }
      this.formData.year = tab.label;
    },
    /**
     * 提交按钮
     */
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        if (this.formData.id) {
          this.upJJZB();
        } else {
          this.addJJZB();
        }
        if (sessionStorage.getItem("openYh") == "true") {
          this.$confirm("是否返回系统推荐?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.setItem("openYh", "false");
              this.$router.push({
                name: "policyMatchReport",
              });
            })
            .catch(() => {});
        }
      });
    },
    /**
     * 重置按钮
     */
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
  },
};
</script>
<style lang="scss" scoped>
.jj-form {
}
.tips-text {
  // position: absolute;
  right: 0;
  top: 18px;
}
</style>
<style lang="scss">
.jj-form {
  .el-tabs__nav {
    height: 40px !important;
  }
}
</style>
