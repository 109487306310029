<template>
  <div class="dz-form">
    <el-row :gutter="0">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="111px"
      >
        <el-col :span="24">
          <el-form-item label="地址" prop="address">
            <el-input
              v-model="formData.address"
              placeholder="请输入地址"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="办公面积(㎡)" prop="bgmj">
            <el-input
              v-model="formData.bgmj"
              placeholder="请输入办公面积(㎡)"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="使用类型" prop="sylx">
            <el-select
              v-model="formData.sylx"
              placeholder="请选择使用类型"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
              <el-option
                v-for="(item, index) in field106Options"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="租金" prop="zj">
            <el-input
              v-model="formData.zj"
              placeholder="请输入租金"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="租期" prop="zq">
            <el-date-picker
              v-model="formData.zq"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              :style="{ width: '100%' }"
              placeholder="请选择租期"
              clearable
              :disabled="tijBtnShow == false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="24">
          <el-form-item label="" v-show="tijBtnShow">
            <span style="color: red">编辑后请点击保存按钮！</span>
          </el-form-item>
        </el-col> -->
        <el-col :span="24">
          <el-form-item size="large">
            <!-- <el-button v-show="tijBtnShow == true"  type="primary" @click="submitForm">{{ formData.id ? '修改' : '新建' }}</el-button> -->
            <el-button
              v-show="tijBtnShow == true"
              type="primary"
              @click="submitForm"
              >保存</el-button
            >
            <!-- <el-button @click="tijBtnShow = !tijBtnShow" type="primary">{{
              tijBtnShow ? "取消" : "编辑"
            }}</el-button> -->
            <!-- <el-button @click="resetForm">重置</el-button> -->
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import WorkTabs from "../com/workTabs"

export default {
  components: {
    //   WorkTabs
  },
  props: [],
  data() {
    return {
      tijBtnShow: true,
      /* 展示数据 */
      formData: {
        // address: "深圳新一代产业园",
        // bgmj: '100',
        // sylx: '01',
        // zj: '300',
        // zq: "2020-12-25 00:00:00",
        // id: '',
        // webEid:'11111',
      },
      /* 储存所有数据 */
      allFormData: [],
      rules: {
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        bgmj: [
          {
            required: true,
            message: "请输入办公面积(㎡)",
            trigger: "blur",
          },
        ],
        sylx: [
          {
            required: true,
            message: "请选择使用类型",
            trigger: "change",
          },
        ],
        zj: [],
        zq: [],
      },
      field106Options: [],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      accountId: "getAccountId",
    }),
  },
  watch: {},
  created() {
    this.formData.webEid = this.userInfo.tyshxydm;
    // this.formData.webEid = this.accountId
  },
  mounted() {
    this.searchAddressList(); // 查询企业地址列表
    this.getUseType(); // 使用类型字典
    // this.formData.webEid = this.accountId
    // console.log("webEid", this.formData.webEid)
  },
  methods: {
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        if (this.formData.id) {
          this.upAddressDetail();
        } else {
          this.addAddressDetail();
        }
        if (sessionStorage.getItem("openYh") == "true") {
          this.$confirm("是否返回系统推荐?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.setItem("openYh", "false");
              this.$router.push({
                name: "policyMatchReport",
              });
            })
            .catch(() => {});
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    /**
     * 查询企业地址列表
     */
    searchAddressList() {
      let url = "/dev-api/enterprise/address/list";
      let params = {
        pageSize: 100, // 页码大小
        pageNum: 0, // 页码页码
        webEid: this.formData.webEid,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("查询企业地址列表>>>", res);
          if (res.code === 200 && res.rows.length > 0) {
            let resList = JSON.parse(JSON.stringify(res.rows));
            const _that = this;
            _that.allFormData = resList; // 存下所有
            _that.formData = resList[0];
          }
        })
        .catch((err) => {});
    },
    /**
     * 新增企业地址详细信息
     */
    addAddressDetail() {
      let url = "/dev-api/enterprise/address/install";
      let params = this.formData;
      console.log("提交的参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("新增企业地址详细信息>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * 修改企业地址详细信息
     */
    upAddressDetail() {
      let url = "/dev-api/enterprise/address/update";
      let params = this.formData;
      console.log("提交的参数>>>", params);
      delete params.createTime;
      delete params.updateTime;
      delete params.delFlag;
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("修改企业地址详细信息>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * 获取企业地址详细信息
     */
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          console.log("--------------------------------------------");
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        });
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          console.log(`${title || ""}处理前>>>`, res);
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          console.log(`获取${title || ""}处理后的数据>>>`, target);
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 使用类型
     */
    getUseType() {
      this.getClassWrap("sys_use_type", "获取使用类型").then((res) => {
        this.field106Options = res;
      });
    },
  },
};
</script>
<style>
</style>
<style lang="scss">
</style>
