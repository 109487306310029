<template>
  <div>
    <el-row :gutter="0">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="144px"
      >
        <el-col :span="12">
          <el-form-item label="联系人姓名" prop="name">
            <el-input
              v-model="formData.name"
              placeholder="请输入联系人姓名"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系人类型" prop="lxrlx">
            <el-select
              v-model="formData.lxrlx"
              placeholder="请选择联系人类型"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
              <el-option
                v-for="(item, index) in field102Options"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件类型" prop="zjlx">
            <el-select
              v-model="formData.zjlx"
              placeholder="请选择证件类型"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
              <el-option
                v-for="(item, index) in field105Options"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="证件号码" prop="zjh">
            <el-input
              v-model="formData.zjh"
              placeholder="请输入证件号码"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="职务" prop="zw">
            <el-input
              v-model="formData.zw"
              placeholder="请输入职务"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model="formData.phone"
              placeholder="请输入手机号"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="QQ号" prop="qq">
            <el-input
              v-model="formData.qq"
              placeholder="请输入QQ号"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="固定电话" prop="gddh">
            <el-input
              v-model="formData.gddh"
              placeholder="请输入固定电话"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="微信号" prop="wxh">
            <el-input
              v-model="formData.wxh"
              placeholder="请输入微信号"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属地区" prop="ssdq">
            <el-select
              v-model="formData.ssdq"
              placeholder="请选择所属地区"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
              <el-option
                v-for="(item, index) in ssdqData"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            <el-input
              v-model="formData.email"
              placeholder="请输入邮箱"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="传真" prop="chz">
            <el-input
              v-model="formData.chz"
              placeholder="请输入传真"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <!-- <el-form-item label="" v-show="tijBtnShow">
            <span style="color: red">编辑后请点击保存按钮！</span>
          </el-form-item> -->
          <el-form-item size="large">
            <!-- <el-button
              v-show="tijBtnShow == true"
              type="primary"
              @click="submitForm"
              >{{ formData.id ? "修改" : "新建" }}</el-button
            > -->
            <el-button
              v-show="tijBtnShow == true"
              type="primary"
              @click="submitForm"
              >保存</el-button
            >
            <!-- <el-button @click="tijBtnShow = !tijBtnShow" type="primary">{{
              tijBtnShow ? "取消" : "编辑"
            }}</el-button> -->
            <!-- <el-button @click="resetForm">重置</el-button> -->
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: [],
  data() {
    return {
      tijBtnShow: true,
      formData: {
        /*name: '会提示',
			lxrlx: '1',
			zjlx: '',
			zjh: '43026235315158336',
			zw: 'CEO',
			phone: '18574582658',
			qq: '1586488',
			gddh: '',
			wxh: 'FSEWFDSAF',
			chz: '',
			email: '153456825@qq.com',*/
        name: "",
        lxrlx: "",
        zjlx: "",
        zjh: "",
        zw: "",
        phone: "",
        qq: "",
        gddh: "",
        wxh: "",
        chz: "",
        email: "",
        ssdq: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "blur",
          },
        ],
        lxrlx: [
          {
            required: true,
            message: "请选择联系人类型",
            trigger: "change",
          },
        ],
        zjlx: [
          {
            required: true,
            message: "请选择证件类型",
            trigger: "change",
          },
        ],
        zjh: [
          {
            required: true,
            pattern:
              /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
            message: "请输入正确的证件号码",
            trigger: "blur",
          },
        ],
        zw: [
          {
            required: true,
            message: "请输入职务",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        qq: [
          {
            pattern: /^[1-9][0-9]{4,12}$/,
            message: "请输入正确的qq号",
            trigger: "blur",
          },
        ],
        gddh: [
          {
            // pattern:/^(852)?((2[1-9]|3[145679]|5[1234569]9[0-8])\d{6}|6\d{7})$/,
            pattern: /^\d{3}\d{7,8}|\d{4}\d{7,8}$/,
            message: "请输入正确的固定电话",
            trigger: "blur",
          },
        ],
        wxh: [],
        chz: [
          {
            // pattern: /^(?:\d{3,4}-)?\d{7,8}(?:-\d{1,6})?$/,
            pattern: /^\d{7,8}|\d{3}\d{7,8}$/,
            message: "请输入正确的传真号",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            pattern:
              /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            message: "请输入正确的邮箱号码",
            trigger: "blur",
          },
        ],
        ssdq: [
          {
            required: true,
            message: "请输入所属地区",
            trigger: "blur",
          },
        ],
      },
      field102Options: [],
      field105Options: [],
      ssdqData: [], //所属地区
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      accountId: "getAccountId",
    }),
  },
  watch: {},
  created() {
    this.formData.webEid = this.userInfo.tyshxydm;
    // this.formData.webEid = this.accountId
  },
  mounted() {
    this.getList(); // 获取列表
    this.getContactsType();
    this.getIdCardType();
    this.getSsdq();
  },
  methods: {
    /**
     * 新建 或 修改
     *
     */
    addUp() {
      let url = this.formData.id
        ? "/dev-api-jh/enterprise/contact/update"
        : "/dev-api-jh/enterprise/contact/install";
      this.formData.createTime = new Date(this.formData.createTime);
      let params = this.formData;
      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("返回>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },

    /**
     * 获取联系人列表
     *
     * @param { 页面，页面大小，webEid }
     * @returns { Array }	账户列表
     */
    getList() {
      let url = "/dev-api-jh/enterprise/contact/webId/" + this.formData.webEid;
      /*let params = {
                pageSize: 100,    // 页码大小
				pageNum: 0,  // 页码页码
				webEid: this.formData.webEid,
            }*/
      this.$httpApi
        .get(url)
        .then((res) => {
          console.log("获取联系人列表>>>", res);
          if (res.code === 200) {
            let resList = JSON.parse(JSON.stringify(res.data));
            const _that = this;
            _that.allFormData = resList; // 存下所有
            _that.formData = resList;
          }
        })
        .catch((err) => {});
    },
    /**
     * btn
     *
     */
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        this.addUp();
        if (sessionStorage.getItem("openYh") == "true") {
          this.$confirm("是否返回系统推荐?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.setItem("openYh", "false");
              this.$router.push({
                name: "policyMatchReport",
              });
            })
            .catch(() => {});
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          // console.log("--------------------------------------------")
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 联系人类型
     */
    getContactsType() {
      this.getClassWrap("sys_contacts_type", "获取联系人类型").then((res) => {
        this.field102Options = res;
      });
    },
    /**
     * 证件类型
     */
    getIdCardType() {
      this.getClassWrap("sys_id_card_type", "获取证件类型").then((res) => {
        this.field105Options = res;
      });
    },
    // 获取所属地区
    getSsdq() {
      this.getClassWrap("ssdq", "获取所属地区").then((res) => {
        this.ssdqData = res;
      });
    },
  },
};
</script>
<style>
</style>
