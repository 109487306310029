<template>
  <div>
    <el-row :gutter="0">
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="144px"
      >
        <el-col :span="12">
          <el-form-item label="账户名称" prop="bank">
            <el-input
              v-model="formData.bank"
              placeholder="请输入账户名称"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属银行" prop="ssyh">
            <el-select
              v-model="formData.ssyh"
              placeholder="请选择所属银行"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
              <el-option
                v-for="(item, index) in ssyhOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="支行名称" prop="zhmc">
            <el-input
              v-model="formData.zhmc"
              placeholder="请输入支行名称"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="联系电话" prop="phone">
            <el-input
              v-model="formData.phone"
              placeholder="请输入联系电话"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="账号" prop="account">
            <!--  minlength="11"
              maxlength="19" -->
            <el-input
              v-model="formData.account"
              placeholder="请输入账号"
              clearable
              :style="{ width: '100%' }"
              :disabled="tijBtnShow == false"
            >
            </el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
			<el-form-item label="账户类型" prop="field105">
				<el-select v-model="formData.field105" placeholder="请选择账户类型" clearable :style="{width: '100%'}" :disabled="tijBtnShow == false" >
				<el-option v-for="(item, index) in field105Options" :key="index" :label="item.label"
					:value="item.value" :disabled="item.disabled"></el-option>
				</el-select>
			</el-form-item>
			</el-col> -->
        <el-col :span="24">
          <!-- <el-form-item label="" v-show="tijBtnShow">
            <span style="color: red">编辑后请点击保存按钮！</span>
          </el-form-item> -->
          <el-form-item size="large">
            <!-- <el-button v-show="tijBtnShow == true"  type="primary" @click="submitForm">{{ formData.id ? '修改' : '新建' }}</el-button> -->
            <el-button
              v-show="tijBtnShow == true"
              type="primary"
              @click="submitForm"
              >保存</el-button
            >
            <!-- <el-button @click="tijBtnShow = !tijBtnShow" type="primary">{{
              tijBtnShow ? "取消" : "编辑"
            }}</el-button> -->
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: [],
  data() {
    return {
      tijBtnShow: true,
      formData: {
        bank: "", // 账户名称
        ssyh: "006", // 所属银行
        zhmc: "", // 支行名称
        account: "", // 银行卡号
        phone: "", //联系电话
      },
      rules: {
        bank: [
          {
            required: true,
            message: "请输入账户名称",
            trigger: "blur",
          },
        ],
        ssyh: [
          {
            required: true,
            message: "请选择所属银行",
            trigger: "change",
          },
        ],
        zhmc: [
          {
            required: true,
            message: "请输入支行名称",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        field105: [
          {
            required: true,
            message: "请选择账户类型",
            trigger: "change",
          },
        ],
      },
      ssyhOptions: [],
      field105Options: [
        {
          label: "传统银行账户",
          value: 0,
        },
        {
          label: "数字货币账户",
          value: 1,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      userInfo: "getUserInfo",
      accountId: "getAccountId",
    }),
  },
  watch: {},
  created() {
    this.formData.webEid = this.userInfo.tyshxydm;
    // this.formData.webEid = this.accountId
    this.getList();
  },
  mounted() {
    this.getAccType();
  },
  methods: {
    /**
     * 新建 或 修改
     *
     */
    addUp() {
      let url = this.formData.id
        ? "/dev-api/enterprise/bank/update"
        : "/dev-api/enterprise/bank/install";
      let params = this.formData;
      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          console.log("返回>>>", res);
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
            // sessionStorage.setItem("indexActiveName", "yhzh");
            // this.$router.go(0);
          } else if (res.code === 500) {
            this.$message({
              message: "参数错误！",
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },
    /**
     * 获取银行列表
     *
     * @param { 页面，页面大小，webEid }
     * @returns { Array }	账户列表
     */
    getList() {
      let url = "/dev-api/enterprise/bank/list";
      let params = {
        pageSize: 100, // 页码大小
        pageNum: 0, // 页码页码
        webEid: this.formData.webEid,
      };
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("获取银行列表>>>", res);
          if (res.code === 200 && res.rows.length > 0) {
            let resList = JSON.parse(JSON.stringify(res.rows));
            const _that = this;
            _that.allFormData = resList; // 存下所有
            _that.formData = resList[0];
          }
        })
        .catch((err) => {});
    },
    /**
     * 按钮
     */
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) return;
        // TODO 提交表单
        this.addUp();
        if (sessionStorage.getItem("openYh") == "true") {
          this.$confirm("是否返回系统推荐?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.setItem("openYh", "false");
              this.$router.push({
                name: "policyMatchReport",
              });
            })
            .catch(() => {});
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          console.log("--------------------------------------------");
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 所属银行
     */
    getAccType() {
      this.getClassWrap("sys_acc_bank", "获取所属银行").then((res) => {
        // this.field106Options = res
        this.ssyhOptions = res;
      });
    },
  },
};
</script>
<style>
</style>
