<!--  -->
<template>
  <div>
    <h3 style="font-size: 18px; font-weight: 600; color: #333">
        知识产权
    </h3>
    <div style="margin-top: 1rem">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        header-row-style="
        color:#333;
        font-size:16px
      "
      >
        <el-table-column prop="type" label="项目类型"> </el-table-column>
        <el-table-column prop="year20" label="截止2020末"> </el-table-column>
        <el-table-column prop="year19" label="截止2019末"> </el-table-column>
        <el-table-column prop="year18" label="截止2018末"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          type: "知识产权",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "专利",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "发明专利",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "使用新型专利",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "外观专利",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "软件著作权",
          year20: "0",
          year19: "0",
          year18: "0",
        },
      ],
    };
  },

  components: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>
<style lang='less' scoped>
</style>