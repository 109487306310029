<template>
    <div class="setting-container">
        <div class="wrap-header">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                    v-for="(item,index) in tabInfo.list"
                    :key="index"
                    :name="item.name"
                    :label="item.label"
                >
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="pad-2 wrap-cotnent">
            <CompanyBaseInfo v-if="activeName==='jbxx'"></CompanyBaseInfo>
            <CompanyAddress v-if="activeName==='bgdz'"></CompanyAddress>
            <EconomicIndicators v-if="activeName==='jjzb'"></EconomicIndicators>
            <BankAccount v-if="activeName==='yhzh'"></BankAccount>
            <Contact v-if="activeName==='lxr'"></Contact>
            <Rcqk v-if="activeName==='rcqk'"></Rcqk>
            <Tzqk v-if="activeName==='tzqk'"></Tzqk>
            <Zscq v-if="activeName==='zscq'"></Zscq>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import CompanyBaseInfo from "./companyBaseInfo";
import Rcqk from "./talentSituation";
import Tzqk from "./tzSituation";
import Zscq from "./zscq";
import CompanyAddress from "./companyAddress/index";
import EconomicIndicators from "./economicIndicators/index";
import BankAccount from "./bankAccount/index";
import Contact from "./contact/index"

export default {
    components: {
        CompanyBaseInfo,
        CompanyAddress,
        EconomicIndicators,
        BankAccount,
        Contact,
        Rcqk,
        Tzqk,
        Zscq,
    },
    created(){
      let activeName = sessionStorage.getItem('indexActiveName');
      if(activeName){
        this.activeName = activeName;
      }
    },
    data() {
        return {
            activeName: "jbxx",
            tabInfo: {
                list: [
                    { name: 'jbxx', label: '基本信息' },
                    { name: 'bgdz', label: '办公地址' },
                    { name: 'rcqk', label: '人才情况' },
                    { name: 'jjzb', label: '经济指标' },
                    // { name: 'tzqk', label: '投资情况' },
                    // { name: 'zscq', label: '知识产权' },
                    { name: 'yhzh', label: '银行账户' },
                    { name: 'lxr', label: '联系人' },
                ],
            },
            /* 用户管理表单 */
            ruleForm: {
                name: "",
                id: "",
                area: "",
                address: "",
                phone: "18574356528",
                email: "",
                level: "",
            },
            rules: {
                name: [
                    { required: true, message: "请输入活动名称", trigger: "blur" },
                    { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
                ],
                id: [
                    { required: true, message: "请填写证件号码", trigger: "blur" },
                    {
                        pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                        message: "证件号码格式有误！",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo",
        }),
    },
    methods: {
       handleClick(tab, event){
        if(tab.name!='jjzb'){
          sessionStorage.setItem('indexActiveName','');
          sessionStorage.setItem('activeName','');
        }
       },
        /* 用户管理，方法 */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    // console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>

<style lang="scss" scoped>
.setting-container {
}
.wrap-header {
    margin-bottom: 10px;
    background-color: #ffffff;
}
::v-deep .el-tabs__header {
    padding-top: 5px;

    .el-tabs__nav-wrap {
        .el-tabs__item {
            padding-right: 20px;
            padding-left: 20px;
            font-size: 16px;
            font-weight: bold;

            &.is-active {
                color: $col-blue;
            }
        }
        .el-tabs__active-bar {
            background-color: $col-blue;
        }

        &::after {
            display: none;
        }
    }
}
.wrap-cotnent {
    background-color: #ffffff;
}
</style>
