<!--  -->
<template>
  <div>
    <h3 style="font-size: 18px; font-weight: 600; color: #333">
      投资情况（万元）
    </h3>
    <div style="margin-top: 1rem">
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        header-row-style="
        color:#333;
        font-size:16px
      "
      >
        <el-table-column prop="type" label="项目类别"> </el-table-column>
        <el-table-column prop="year20" label="2020"> </el-table-column>
        <el-table-column prop="year19" label="2019"> </el-table-column>
        <el-table-column prop="year18" label="2018"> </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          type: "资产投入",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "信息化投入",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "生产管理软件",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "办公自动化软件",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "电脑设备",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "机器设备投入（技改）",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "机器类投入",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "仪器类投入",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "节能减排类设备投入",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "品牌推广费用",
          year20: "0",
          year19: "0",
          year18: "0",
        },
        {
          type: "研发费用",
          year20: "0",
          year19: "0",
          year18: "0",
        },
      ],
    };
  },

  components: {},

  created() {},

  mounted() {},

  methods: {},
};
</script>
<style lang='less' scoped>
</style>