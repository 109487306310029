<template>
  <div>
    <el-form
      ref="elForm"
      :model="formData"
      :rules="rules"
      size="small"
      label-width="150px"
    >
      <el-row type="flex" justify="start" align="middle">
        <el-form-item
          label-width="150px"
          label="专科以下人数(人)"
          prop="zkyxrs"
        >
          <el-input
            v-model="formData.zkyxrs"
            placeholder="请输入专科以下人数(人)"
            clearable
            :style="{ width: '100%' }"
            :disabled="tijBtnShow == false"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="专科人数(人)" prop="zkrs">
          <el-input
            v-model="formData.zkrs"
            placeholder="请输入专科人数(人)"
            clearable
            :style="{ width: '100%' }"
            :disabled="tijBtnShow == false"
          >
          </el-input>
        </el-form-item>
      </el-row>

      <el-row type="flex" justify="start" align="middle">
        <el-form-item label-width="150px" label="本科人数(人)" prop="bkrs">
          <el-input
            v-model="formData.bkrs"
            placeholder="请输入本科人数(人)"
            clearable
            :style="{ width: '100%' }"
            :disabled="tijBtnShow == false"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="硕士人数(人)" prop="ssjysrs">
          <el-input
            v-model="formData.ssjysrs"
            placeholder="请输入硕士人数(人)"
            clearable
            :style="{ width: '100%' }"
            :disabled="tijBtnShow == false"
          >
          </el-input>
        </el-form-item>
      </el-row>

      <el-row type="flex" justify="start" align="middle">
        <el-form-item label-width="150px" label="博士人数(人)" prop="bsrs">
          <el-input
            v-model="formData.bsrs"
            placeholder="请输入博士人数(人)"
            clearable
            :style="{ width: '100%' }"
            :disabled="tijBtnShow == false"
          >
          </el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <!-- <el-form-item label="" v-show="tijBtnShow">
          <span style="color: red">编辑后请点击保存按钮！</span>
        </el-form-item> -->
        <el-form-item label-width="150px" label="">
          <!-- <el-button
            v-show="tijBtnShow == true"
            type="primary"
            @click="submitForm"
            >{{ formData.id ? "修改" : "新建" }}</el-button
          > -->
          <el-button
            v-show="tijBtnShow == true"
            type="primary"
            @click="submitForm"
            >保存</el-button
          >
          <!-- <el-button @click="tijBtnShow = !tijBtnShow" type="primary">{{
            tijBtnShow ? "取消" : "编辑"
          }}</el-button> -->
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: [],
  data() {
    return {
      /* 提交按钮 */
      tijBtnShow: true,
      /* tabs相关 */
      editableTabsValue: "2",
      editableTabs: [
        {
          title: "Tab 1",
          name: "1",
          content: "Tab 1 content",
        },
        {
          title: "Tab 2",
          name: "2",
          content: "Tab 2 content",
        },
      ],
      tabIndex: 2,
      /* 是 新增 0 还是 修改 1 状态 */
      addUpStatus: 0,
      /* 表单数据 */
      formData: {
        name: "欣旺达电子股份有限公司",
        shxydm: "91440300279446850J",
        frdb: "王威",
        frsfz: "430623198901018888",
        zcsj: "1990-08-23 00:00:00",
        zcjg: "深圳市场监督管理局",
        zczb: "157497",
        djzt: "1",
        qyxz: "2",
        cylx: "7",
        qywz: "http://www.sztaiji.com",
        gwjjfl: "A",
        qycz: "0755-86309810",
        qyyx: "284888888@qq.com",
        rzqd: ["1000", "1003"],
        qyyd: ["in_normal"],
        zcdz: "深圳新一代产业园3栋1402",
        zbdz: "136号深圳新一代产业园3栋1402",
        bgly: "新一代产业园3栋1402",
        xzqh1: [1, 11, 111],
        xzqh2: "1", // ??
        xzqh3: "1", // ??
        xzqhdm: "",
        zczbdw: "840", // ??
        webEid: "11111", // ??
        sspq: 1,
        ssjd: 1,
        bgmj: "1678",
        zlyfmj: "1687",
        ggyfmj: "0",
        zjyfmj: "0",
        zkyxrs: "9",
        ssjysrs: "9",
        bsrs: "12",
        bkrs: "375",
        zkrs: "140",
        // jsrs: "450",
        // zczrs: "533",
        fzjgsl: "0",
        hwfzjgsl: "0",
        hwyxjgsl: "0",
        sfjldzz: "1",
        fmzls: "2",
        ssqk: "listed",
        ssbk: "02",
        ssdm: "3213423",
        jyfw: "要点1：所属板块 MSCI中国 MSCI中盘 创业板综 创业成份 富时概念 广东板块 华为概念 苹果概念 融资融券 深成500 深股通 深圳特区 石墨烯 输配电气 特斯拉 无线充电 无线耳机 小米概念 新能源车 虚拟现实 证金持股 中证500 纾困概念 锂电池要点2：经营范围 电池、充电器、精密模具、精密注塑、仪器仪表、工业设备、自动化设备及产线的研发、制造、销售;电子产品的研发、制造、销售;动力电池系统、储能电池及储能系统的研发、制造、销售;软件开发及销售;锂离子电池、蓄电池、蓄电池组的实验室检测、技术咨询服务;医疗器械;医疗安全系列产品;工业防护用品;劳动防护用品等研发、生产、销售;移动基站、通信设备、电子触控笔、家电类、音箱类、灯具类、转换器类、电器开关的研发、生产和销售;玩具生产及销售;锂离子电池材料、高性能膜材料、电解液材料的研发、生产、销售;兴办实业(具体项目另行申报),国内商业、物资供销业,货物及技术进出口;物业租赁;普通货运;(以上项目均不含法律、行政法规、国务院决定规定需前置审批及禁止项目)。",
        qyjj: "欣旺达电子股份有限公司(以下简称“本公司”)前身为“深圳欣旺达电子有限公司”,成立于1997年12月9日，系在深圳工商行政管理局登记注册的有限责任公司，注册号为4403012038699，注册资本为人民币100万元。其中：王明旺出资78万元，持有公司78%的股权；王威出资22万元，持有公司22%的股权。2008年7月，公司股东王明旺、王威（以下简称“甲方”）与湖南邦普循环科技有限公司、深圳市欣明达投资有限公司(以下简称“欣明达投资”)、肖光昱、项海",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入企业名称企业名称",
            trigger: "blur",
          },
        ],
        shxydm: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: "blur",
          },
        ],
        frdb: [
          {
            required: true,
            message: "请输入法人代表",
            trigger: "blur",
          },
        ],
        frsfz: [
          {
            required: true,
            pattern:
              /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
            message: "请输入正确的身份证号",
            trigger: "blur",
          },
        ],
        zcsj: [
          {
            required: true,
            message: "请选择注册时间",
            trigger: "blur",
          },
        ],
        zcjg: [
          {
            required: true,
            message: "请输入注册机关",
            trigger: "blur",
          },
        ],
        zczb: [
          {
            required: true,
            message: "请输入注册资本(万)",
            trigger: "blur",
          },
        ],
        gwjjfl: [
          {
            required: true,
            message: "请选择国民经济分类",
            trigger: "blur",
          },
        ],
        djzt: [
          {
            required: true,
            message: "请输入登记状态",
            trigger: "change",
          },
        ],
        qyxz: [
          {
            required: true,
            message: "请选择企业性质",
            trigger: "change",
          },
        ],
        cylx: [
          {
            required: true,
            message: "请选择产业类型",
            trigger: "change",
          },
        ],
        qywz: [],
        gwjjfl: [],
        qycz: [],
        qyyx: [
          {
            message: "请输入正确的邮箱号",
            pattern:
              /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/,
            trigger: "blur",
          },
        ],
        rzqd: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个融资渠道",
            trigger: "change",
          },
        ],
        qyyd: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个企业异动",
            trigger: "change",
          },
        ],
        zcdz: [
          {
            required: true,
            message: "请输入注册地址",
            trigger: "blur",
          },
        ],
        zbdz: [],
        bgly: [],
        xzqh1: [
          {
            required: true,
            type: "array",
            message: "请至少选择一个行政区划",
            trigger: "change",
          },
        ],
        xzqhdm: [
          {
            required: true,
            message: "请输入行政区划代码",
            trigger: "blur",
          },
        ],
        sspq: [
          {
            required: true,
            message: "请选择所属片区",
            trigger: "change",
          },
        ],
        ssjd: [
          {
            required: true,
            message: "请选择所属街道",
            trigger: "change",
          },
        ],
        bgmj: [],
        zlyfmj: [],
        ggyfmj: [],
        zjyfmj: [],
        zkyxrs: [],
        ssjysrs: [],
        bsrs: [],
        bkrs: [],
        zkrs: [],
        // jsrs: [
        //     {
        //         required: true,
        //         message: "请输入研发/技术人数(人)",
        //         trigger: "blur",
        //     },
        // ],
        // zczrs: [
        //     {
        //         required: true,
        //         message: "请输入职工总人数(人)",
        //         trigger: "blur",
        //     },
        // ],
        fzjgsl: [
          {
            required: true,
            message: "请输入分支机构数量",
            trigger: "blur",
          },
        ],
        hwfzjgsl: [],
        hwyxjgsl: [],
        sfjldzz: [
          {
            required: true,
            message: "是否建立党组织不能为空",
            trigger: "change",
          },
        ],
        fmzls: [],
        ssqk: [],
        ssbk: [],
        ssdm: [],
        jyfw: [],
        qyjj: [],
      },
      cylxOptions: [],
      djztOptions: [],
      gwjjflOptions: [
        {
          id: 1,
          value: 1,
          label: "选项1",
        },
        {
          id: 1,
          value: 1,
          label: "选项1",
        },
      ],
      rzqdOptions: [],
      qyydOptions: [],
      xzqh1Options: [],
      sspqOptions: [
        {
          label: "梅林彩田片区",
          value: 1,
        },
        {
          label: "梅林美景",
          value: 2,
        },
      ],
      ssjdOptions: [
        {
          label: "梅林街道",
          value: 1,
        },
        {
          label: "梅景街道",
          value: 2,
        },
      ],
      sfjldzzOptions: [],
      ssqkOptions: [],
      ssbkOptions: [],
      gwjjflProps: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
      },
      xzqh1Props: {
        multiple: false,
        label: "label",
        value: "value",
        children: "children",
        checkStrictly: true,
      },
    };
  },
  computed: {
    ...mapGetters({
      userBaseInfo: "getUserInfo",
      getDictList: "dictionary/getDictList",
    }),
  },
  methods: {
    /**
     * 多个请求字典列表
     */
    getAllList() {
      this.getClassificationNationalEconomy(); // 获取国名经济分类
      this.getIndustryType(); // 获取产业类型
      this.getRegistrationStatus(); // 获取登记状态
      this.getFinancingChannels(); // 获取融资渠道
      this.getEnterpriseMobility(); // 获取企业异动
      this.getIsOrga(); // 是否建立党组织
      this.getStockStatus(); // 上市情况
      this.getStockMarket(); // 上市板块
      this.getqhdj(); // 获取区划等级
    },
    /**
     * 通用：根据字典类型获取字典信息
     */
    async getDictionaryInfo(el) {
      let response;
      await this.$httpApi
        .get("/dev-api/system/dict/getDictListByType", { dictType: el })
        .then((res) => {
          // console.log("根据字典类型获取字典信息>>>", res)
          // console.log("--------------------------------------------")
          if (res.code == 200 && res.data.length !== 0) {
            response = res;
          } else {
            response = 0;
          }
        })
        .catch((err) => {});
      return response;
    },
    /**
     * 处理数据通用
     */
    async getClassWrap(el, title) {
      let endList = [];
      await this.getDictionaryInfo(el).then((res) => {
        if (res === 0) {
          console.log("字典暂无数据>>>");
        } else {
          // 处理数据
          // console.log(`${title || ''}处理前>>>`, res)
          const resList = JSON.parse(JSON.stringify(res.data));
          let target = [];
          for (const item of resList) {
            target.push({
              id: item.sort, // 0
              value: item.key, // "A"
              label: item.label, // "农、林..."
            });
          }
          // console.log(`获取${title || ''}处理后的数据>>>`, target)
          endList = target;
        }
      });
      return endList;
    },
    /**
     * 登记状态
     */
    getRegistrationStatus() {
      this.getClassWrap("sys_reg_status", "获取登记状态").then((res) => {
        this.djztOptions = res;
      });
    },
    /**
     * 获取产业类型
     */
    getIndustryType() {
      this.getClassWrap("sys_enter_type", "获取获取产业类型").then((res) => {
        this.cylxOptions = res;
      });
    },
    /**
     * 获取国名经济分类
     */
    getClassificationNationalEconomy() {
      this.getClassWrap("sys_category", "获取国名经济分类").then((res) => {
        this.gwjjflOptions = res;
      });
    },
    /**
     * 融资渠道
     */
    getFinancingChannels() {
      this.getClassWrap("sys_finance_channel", "获取融资渠道").then((res) => {
        console.log("lzqd>>>", res);
        this.rzqdOptions = res;
      });
    },
    /**
     * 企业异动
     */
    getEnterpriseMobility() {
      this.getClassWrap("sys_enter_unusual", "企业异动").then((res) => {
        this.qyydOptions = res;
      });
    },
    /**
     * 是否建立党组织
     */
    getIsOrga() {
      this.getClassWrap("sys_build_party_orga", "是否建立党组织").then(
        (res) => {
          this.sfjldzzOptions = res;
        }
      );
    },
    /**
     * 上市情况
     */
    getStockStatus() {
      this.getClassWrap("sys_stock_status", "上市情况").then((res) => {
        this.ssqkOptions = res;
      });
    },
    /**
     * 上市板块
     */
    getStockMarket() {
      this.getClassWrap("sys_stock_market", "上市板块").then((res) => {
        this.ssbkOptions = res;
      });
    },
    /**
     * 获取区划等级
     */
    getqhdj() {
      this.$httpApi
        .get("/dev-api/cfginfo/region/cascaderTreeList", {})
        .then((res) => {
          console.log("区划等级>>>", res);
          if (res.code == 200) {
            let resData = res.rows;
            this.xzqh1Options = JSON.parse(JSON.stringify(resData));
          }
        })
        .catch((err) => {});
    },
    /**
     * 对  "1001, 1002"  <---> ["1001", "1002"] 格式的转换
     *
     * @param { String }    情况1：传入的是 "1001, 1002" 此时应该是后台传的格式
     * @param { Array }     情况2：传入的是 ["1001", "1002"] 此时是 组件 选择后的，传给后台需要转成字符串
     *
     * @returns { Arrar , String }  依据情况转成对应的格式
     */
    transformSA(el) {
      let target;
      if (Array.isArray(el)) {
        // 如果是数组
        target = el.toString();
      } else if (typeof el === "string") {
        //  如果是字符串
        target = el.split(",");
      } else {
        console.log("非法传参");
        target = [];
      }
      // console.log("最后输出值>>>", target)
      return target;
    },

    /**
     * 提交 和 重置 按钮
     */
    submitForm() {
      this.$refs["elForm"].validate((valid) => {
        // console.log("提交按钮>>>")
        if (!valid) return;
        // TODO 提交表单
        this.addEnterpriseBase();
        if (sessionStorage.getItem("openYh") == "true") {
          this.$confirm("是否返回系统推荐?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              sessionStorage.setItem("openYh", "false");
              this.$router.push({
                name: "policyMatchReport",
              });
            })
            .catch(() => {});
        }
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
      // console.log("重置按钮>>>")
    },
    /**
     * 删除企业用户基本信息
     */
    deleteEnterpriseBase() {},
    /**
     * 新增企业用户基本信息 and 修改外网企业用户基本信息
     */
    addEnterpriseBase() {
      let url =
        this.addUpStatus == 0
          ? "/dev-api/enterprise/baseInfo/install"
          : "/dev-api/enterprise/baseInfo/update";
      let params = JSON.parse(JSON.stringify(this.formData));
      let _that = this;

      // console.log("状态>>>", this.addUpStatus, url)
      /* 对数据['1001', '1002'] 格式进行清洗*/
      let stringList = ["rzqd", "qyyd", "xzqh1"];
      let xzqh2;
      let xzqh3;
      for (const i in params) {
        for (const item of stringList) {
          if (i.indexOf(item) !== -1) {
            params[i] = _that.transformSA(params[i]);
            // console.log(">>>>", i)
          }
        }
      }
      let areaids = params["xzqh1"].split(",");
      if (!areaids[1]) {
        //只选择市
        params["xzqh1"] = "411100000000";
        params["xzqh2"] = "411100000000";
        params["xzqh3"] = "411100000000";
        params["xzqhdm"] = "411100000000";
      }
      if (areaids[1] && !areaids[2]) {
        //选择到区
        params["xzqh1"] = areaids[1];
        params["xzqh2"] = areaids[1];
        params["xzqh3"] = areaids[1];
        params["xzqhdm"] = areaids[1];
      }
      if (areaids[2] && !areaids[3]) {
        //选择到街道
        params["xzqh1"] = areaids[1];
        params["xzqh2"] = areaids[2];
        params["xzqh3"] = areaids[2];
        params["xzqhdm"] = areaids[2];
      }
      if (areaids[3] && !areaids[4]) {
        //选择到居委会
        params["xzqh1"] = areaids[1];
        params["xzqh2"] = areaids[2];
        params["xzqh3"] = areaids[3];
        params["xzqhdm"] = areaids[3];
      }
      /**
       * 20210419
       * gwjjfl国民经济分类，这个字段接收String，但是表单中用了cascader，会是一个Array
       * 手动转一下
       */
      if (typeof params.gwjjfl !== "string") {
        params.gwjjfl = params.gwjjfl.toString();
      }
      /**
       * 20210526
       * 一些字段需要预处理一下
       */
      // Int类型的字段，只能传Int  //'jsrs',   // 'zczrs',
      let intList = [
        "bgmj",
        "bkrs",
        "bsrs",
        "fmzls",
        "fzjgsl",
        "ggyfmj",
        "hwfzjgsl",
        "hwyxjgsl",
        "ssjysrs",
        "zczb",
        "zjyfmj",
        "zkrs",
        "zkyxrs",
        "zlyfmj",
      ];
      for (let i = 0; i < intList.length; i++) {
        params[intList[i]] = Number(params[intList[i]] || 0);
      }
      // 注册资本 写死 人民币
      params.zczbdw = "129";

      if (this.addUpStatus == 1) {
        delete params.createTime;
        delete params.updateTime;
      }

      console.log("提交参数>>>", params);
      this.$httpApi
        .postByBody(url, params)
        .then((res) => {
          // console.log("新增企业用户基本信息列表>>>", res)
          if (res.code === 200) {
            this.$message({
              message: "提交成功！",
              type: "success",
            });
            // this.tijBtnShow = false;
          } else if (res.code === 500) {
            this.$message({
              message: `${res.msg}`,
              type: "error",
            });
          }
        })
        .catch((err) => {});
    },

    /**
     * 企业用户基本信息列表
     */
    getEnterpriseList() {
      let url = "/dev-api/enterprise/baseInfo/list";

      let params = {
        pageSize: 10, // 页大小
        pageNum: 1, // 页码
        // webEid: this.formData.webEid,
        shxydm: this.userBaseInfo.tyshxydm,
      };
      // console.log("企业用户基本信息列表params>>>", params)
      this.$httpApi
        .get(url, params)
        .then((res) => {
          console.log("企业用户基本信息列表>>>", res);
          if (res.code === 200 && res.rows.length > 0) {
            let resList = JSON.parse(JSON.stringify(res.rows));
            const _that = this;
            const resLen = resList.length; // 是否含有数据？
            this.addUpStatus = resLen !== 0 ? "1" : "0"; // 1 是修改 0 是新增
            // console.log("获取列表后的状态 ", resLen ,this.addUpStatus)
            /* 清晰数据： ！！！这里其实还可以对清洗封装，传入 1. 需要清洗的对象 2. 需要匹配的数组 */
            /* 对数据'1001, 1002' 格式进行清洗*/
            let stringList = ["rzqd", "qyyd"];
            let target = resList[0];
            /**
             * 20210625
             * 初始值为'null'的，修正为''
             */
            for (const [k, v] of Object.entries(target)) {
              if (v === "null") {
                target[k] = "";
              }
            }
            for (const i in target) {
              for (const item of stringList) {
                if (i.indexOf(item) !== -1) {
                  target[i] = _that.transformSA(target[i]);
                  // console.log(">>>>", i)
                }
              }
            }

            // 对他进行再清洗："xzqh1"
            // let myxzqh = target.xzqh1
            // let newxzqh = [];
            // for (let j = 0; j < myxzqh.length; j++) {
            //     newxzqh[j] = parseInt(myxzqh[j])
            // }

            //市级区划回显
            if (Number(target.xzqh1) == "411100000000") {
              target.xzqh1 = [411100000000];
            }
            //区级区划回显
            else if (
              Number(target.xzqh1) == Number(target.xzqh2) &&
              Number(target.xzqh2) == Number(target.xzqh3)
            ) {
              target.xzqh1 = [411100000000, Number(target.xzqh1)];
            }
            //街道级区划回显
            else if (
              Number(target.xzqh1) != Number(target.xzqh2) &&
              Number(target.xzqh2) == Number(target.xzqh3)
            ) {
              target.xzqh1 = [
                411100000000,
                Number(target.xzqh1),
                Number(target.xzqh2),
              ];
            }
            //居委会级区划回显
            else {
              target.xzqh1 = [
                411100000000,
                Number(target.xzqh1),
                Number(target.xzqh2),
                Number(target.xzqh3),
              ];
            }
            console.log("列表清洗后的数据>>>", target);
            this.$store.commit("updateAccountId", target.id);
            this.formData = target;
          }
        })
        .catch((err) => {});
    },

    /**
     * 修改外网企业用户基本信息
     */
    updateEnterpriseBase() {
      // let url = '/dev-api/enterprise/baseInfo/update'
      // let params =  this.formData
      // params = JSON.stringify(params)
      // console.log("提交参数>>>", params)
      // this.$httpApi.postByBody(url, params).then((res) => {
      //     console.log("修改外网企业用户基本信息>>>", res)
      // })
    },
    /**
     * 企业用户基本信息详情
     */
    getEnterpriseBase() {
      let url = "/dev-api/enterprise/baseInfo/" + params;
      let params = {
        id: "chengzjjljle",
      };
      this.$httpApi
        .get(url, {})
        .then((res) => {
          // console.log("get企业用户基本信息>>>", res)
        })
        .catch((err) => {});
    },
  },
  created() {
    this.formData.webEid = this.userBaseInfo.id;
    this.$store.dispatch("dictionary/commonSetDic", {
      types: [
        "sys_regType", // 企业性质
      ],
    });
  },
  mounted() {
    this.getAllList();
    this.getEnterpriseList(); // 企业用户基本信息列表
  },
};
</script>
